import { VuexModule, Module, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'

export interface IQueryStringModule {
    QuoteID: number
}

@Module({ dynamic: true, store, namespaced: true, name: 'queryStringModule' })
class QueryString extends VuexModule implements IQueryStringModule {
    QuoteID = 0;

    previousPath: string | null = null;

    @Mutation
    public setQuoteID(quoteID: string) {
        try {
            const quoteIDNum = parseInt(quoteID);
            if (!isNaN(quoteIDNum)) {
                this.QuoteID = quoteIDNum;
            }
        }
        catch {
        }
    }

    @Mutation
    public setPath(toPath: string | null) {
        if (toPath == '/Login') {
            this.previousPath = '/Customer'
        } else {
            this.previousPath = toPath
        }
    }
}

export const QueryStringModule = getModule(QueryString)