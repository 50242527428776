<template>
    <div v-if="error" class="errorMessage">
        An unknown error has been encountered. Please refresh the page.
    </div>
    <div v-else><slot></slot></div>
</template>

<script lang="ts">

    import { Vue, Options } from 'vue-class-component'

    @Options({
        components: {
        },
        props: {
            disableBoundary: {
                type: Boolean,
                default: false
            }
        }
    })

    export default class ErrorBoundary extends Vue {
        error: Error | null = null
        errorMessage = ''
        disableBoundary!: boolean

        errorCaptured(error: Error, vm: any, info: string) {
            if (!this.disableBoundary) {
                this.error = error;
                this.errorMessage = info;
                console.log(this.error)
                console.log(this.errorMessage)
                return false; // Prevents the error from propagating further
            }
        }
    }
</script>

<style lang="scss" scoped>
    .errorMessage {
        color: #c00;
        font-weight: bold;
        text-align: center;
    }
</style>