import { AccountInfo, AuthenticationResult, ClearCacheRequest, ClientConfigurationError, EndSessionPopupRequest, EndSessionRequest, EventMessage, EventType, NavigationOptions, PopupRequest, RedirectRequest, ServerError, SilentRequest, SsoSilentRequest } from '@azure/msal-browser'
import {
    BrowserAuthError,
    InteractionRequiredAuthError,
    NavigationClient,
    PublicClientApplication
} from '@azure/msal-browser'
import { Config, Scopes } from '@/configs/MsalConfig'
import { Router } from 'vue-router'
import { AzureIdentificationModule } from '@/store/modules/identification/azureIdentification'
import { userCancelled } from '@azure/msal-browser/dist/error/BrowserAuthErrorCodes'

// type
export type MaybeAccount = AccountInfo | null

/**
 * MSAL instance
 */
export const msal = new PublicClientApplication(Config)

/**
 * Auth service
 */
export const Auth = {
    /**
     * Initialize and return active account
     */
    async initialize(client?: NavigationClient): Promise<MaybeAccount> {
        // initialize msal
        await msal.initialize()

        // start msal
        await msal.handleRedirectPromise().then(result => { // For redirect login
            if (result) {
                console.log('Logged in with', result)
                if (result.account != undefined) {
                    AzureIdentificationModule.setToken(result.accessToken)
                }
                this.setAccount(result.account)
            }
            console.log(result)
        }).catch(error => {
            this.clearStorage()
            console.log(error)
            if (error instanceof BrowserAuthError) {
                if (error.errorCode === 'interaction_in_progress') {
                    this.login()
                }
                throw (new Error(error.errorMessage))
            }
        })

        // TODO: Clean up commented out parts
        // Optional - This will update account state if a user signs in from another tab or window
        //msal.enableAccountStorageEvents();

        //msal.addEventCallback((event: EventMessage) => {
        //    console.log(event.eventType);
        //    if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
        //        event.eventType === EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE)
        //    {
        //        msal.clearCache({ // For redirect logout
        //            // required to make the application return to the home page
        //            postLogoutRedirectUri: '/'
        //        } as EndSessionRequest)
        //    }
        //});

        //await msal.handleRedirectPromise() // For popup login

        // hook into application router
        if (client) {
            msal.setNavigationClient(client)
        }

        // grab and set account if in session
        const accounts = msal.getAllAccounts()

        if (accounts?.length) {
            this.setAccount(accounts[0])
        }
        
        // return any active account
        return msal.getActiveAccount()
    },

    /**
     * Login
     */
    async login(): Promise<MaybeAccount> {
        const request: PopupRequest = { // For popup login
            authority: Config.auth.authority,
            //redirectUri: Config.auth.redirectUri,
            scopes: ["openid", "offline_access", ...Scopes],
        }
        try {
            const loginResponse: AuthenticationResult = await msal.loginPopup(request) // For popup login
            if (loginResponse) {
                console.log('Logged in with', loginResponse)
                // set active account
                return this.setAccount(loginResponse.account)
            } else {
                return null
            }
        } catch (error) {
            this.clearStorage()
            if (error instanceof BrowserAuthError) {
                if (error.errorCode === 'interaction_in_progress') {
                    return this.login()
                }
                throw (new Error(error.errorMessage))
            } else {
                console.log(error)
            }
            return null
        }
    },

    /**
    * Login
    */
    async loginRedirect(): Promise<MaybeAccount> {
        const request: RedirectRequest = { // For redirect login
            authority: Config.auth.authority,
            //redirectUri: Config.auth.redirectUri,
            scopes: ["openid", "offline_access", ...Scopes],
        }
        try {
            await msal.loginRedirect(request) // For redirect login
            return null
        } catch (error) {
            this.clearStorage()
            if (error instanceof BrowserAuthError) {
                if (error.errorCode === 'interaction_in_progress') {
                    return this.loginRedirect()
                }
                throw (new Error(error.errorMessage))
            } else {
                console.log(error)
            }
            return null
        }
    },

    /**
    * Login
    */
    async loginSilent(): Promise<MaybeAccount> {
        const request: SsoSilentRequest = { // For silent login
            authority: Config.auth.authority,
            scopes: ["openid", "offline_access", ...Scopes]
        }
        try {
            const loginResponse: AuthenticationResult = await msal.ssoSilent(request) // For silent login
            if (loginResponse) {
                console.log('Logged in silently with', loginResponse)
                // set active account
                return this.setAccount(loginResponse.account)
            } else {
                return null
            }
        } catch (error) {
            console.log(error)
            return null
        }
    },

    /**
    * Login
    */
    async loginRedirectPrompt(): Promise<MaybeAccount> {
        const request: RedirectRequest = { // For redirect login
            authority: Config.auth.authority,
            //redirectUri: Config.auth.redirectUri,
            scopes: ["openid", "offline_access", ...Scopes],
            prompt: 'login'
        }
        try {
            await msal.loginRedirect(request) // For redirect login
            return null
        } catch (error) {
            this.clearStorage()
            if (error instanceof BrowserAuthError) {
                if (error.errorCode === 'interaction_in_progress') {
                    return this.loginRedirect()
                }
                throw (new Error(error.errorMessage))
            } else {
                console.log(error)
            }
            return null
        }
    },


    /**
     * Logout
     */
    async logout() {
        return msal.logoutPopup({ // For popup logout
            // required to make the application return to the home page
            mainWindowRedirectUri: '/'
        } as EndSessionPopupRequest)
    },

    /**
     * Logout
     */
    async logoutRedirect() {
        return msal.logoutRedirect({ // For redirect logout
            // required to make the application return to the home page
            postLogoutRedirectUri: '/'
        } as EndSessionRequest)
    },

    /**
     * Get token for api
     */
    async getToken() {
        const activeAccount = msal.getActiveAccount()
        const request: SilentRequest = {
            account: activeAccount ?? undefined,
            scopes: ["openid", "offline_access", ...Scopes],
            //forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
        }
        return msal
            // try getting the token silently
            .acquireTokenSilent(request)

            // attempt login popup if this fails
            .catch(async (error: unknown) => {
                console.log(error)
                if (error instanceof InteractionRequiredAuthError) {
                    msal.acquireTokenRedirect(request)
                }
                if (error instanceof ClientConfigurationError) {
                    console.log('Issue with client')
                    console.log(msal.getConfiguration())
                }
                throw error
            })
            .then((result: AuthenticationResult) => {
                return result.accessToken
            })
    },

    /**
     * Get token for api
     */
    async getSSOToken() {
        const activeAccount = msal.getActiveAccount()
        const request: SsoSilentRequest = {
            account: activeAccount ?? undefined,
            scopes: ["openid", "offline_access", ...Scopes],
        }
        return msal
            .ssoSilent(request)
            .catch(async (error: unknown) => {
                console.log(error)
                throw error
            })
            .then((result: AuthenticationResult) => {
                return result
            })
    },

    /**
     * Get token for api
     */
    async getTokenForceRefresh() {
        const activeAccount = msal.getActiveAccount()
        const request: SilentRequest = {
            account: activeAccount ?? undefined,
            scopes: ["openid", "offline_access", ...Scopes],
            forceRefresh: true // Set this to "true" to skip a cached token and go to the server to get a new token
        }
        return msal
            // try getting the token silently
            .acquireTokenSilent(request)

            // attempt login popup if this fails
            .catch(async (error: unknown) => {
                console.log(error)
                if (error instanceof InteractionRequiredAuthError) {
                    msal.acquireTokenRedirect(request)
                }
                if (error instanceof ClientConfigurationError) {
                    console.log('Issue with client')
                    console.log(msal.getConfiguration())
                }
                throw error
            })
            .then((result: AuthenticationResult) => {
                return result.accessToken
            })
    },

    /**
     * Change password
     */
    async changePassword() {
        if (Config.auth.knownAuthorities) {
            const request: PopupRequest = { // For popup
                authority: Config.auth.knownAuthorities[1],
                //redirectUri: Config.auth.redirectUri,
                scopes: ["openid", "offline_access", ...Scopes],
            }
            try {
                const loginResponse: AuthenticationResult = await msal.loginPopup(request) // For popup
                if (loginResponse) {
                    console.log('Logged in with', loginResponse)
                    // set active account
                    return this.setAccount(loginResponse.account)
                } else {
                    return null
                }
            } catch (error) {
                if (error instanceof BrowserAuthError) {
                    if (error.errorCode === 'interaction_in_progress') {
                        this.clearStorage()
                        return this.login()
                    }
                    throw (new Error(error.errorMessage))
                } else {
                    console.log(error)
                }
                return msal.getActiveAccount()
            }
        }
        return null
    },

    /**
     * Change password
     */
    async changePasswordRedirect() {
        if (Config.auth.knownAuthorities) {
            const request: RedirectRequest = { // For redirect
                authority: Config.auth.knownAuthorities[1],
                //redirectUri: Config.auth.redirectUri,
                scopes: ["openid", "offline_access", ...Scopes],
            }
            try {
                await msal.loginRedirect(request) // For redirect
                return null
            } catch (error) {
                if (error instanceof BrowserAuthError) {
                    if (error.errorCode === 'interaction_in_progress') {
                        this.clearStorage()
                        return this.login()
                    }
                    throw (new Error(error.errorMessage))
                } else {
                    console.log(error)
                }
                return msal.getActiveAccount()
            }
        }
        return null
    },

    /**
     * Set active account
     * @private
     */
    setAccount(account: MaybeAccount): MaybeAccount {
        msal.setActiveAccount(account)
        return account
    },

    /**
     * Escape hatch when msal gets stuck
     * @private
     */
    reset() {
        if (msal.getConfiguration().cache.cacheLocation == 'localStorage') {
            localStorage.clear()
        } else {
            sessionStorage.clear()
        }
        msal.clearCache({
            // required to make the application return to the home page
            postLogoutRedirectUri: null
        } as ClearCacheRequest)
    },

    /**
     * Escape hatch when msal gets stuck
     * @private
     */
    clearStorage() {
        if (msal.getConfiguration().cache.cacheLocation == 'localStorage') {
            localStorage.clear()
        } else {
            sessionStorage.clear()
        }
    },
}


/**
 * Override MSAL route navigation
 */
export class VueNavigationClient extends NavigationClient {
    private router: Router

    constructor(router: Router) {
        super()
        this.router = router
    }

    /**
     * Only called during redirects
     */
    navigateExternal(url: string, options: NavigationOptions): Promise<boolean> {
        return super.navigateExternal(url, options)
    }

    /**
     * Only called during popup completion
     */
    async navigateInternal(url: string, options: NavigationOptions): Promise<boolean> {
        const path = url.replace(location.origin, '')
        options.noHistory
            ? await this.router.replace(path)
            : await this.router.push(path)
        return true
    }
}
