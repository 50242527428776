import {
    VuexModule, Module, Mutation, getModule, Action
} from 'vuex-module-decorators'
import store from '@/store'
import axios from 'axios'
import { AzureIdentificationModule, grabToken } from './azureIdentification'
import notify from 'devextreme/ui/notify'

@Module({
    dynamic: true, store, namespaced: true, name: 'axiosController'
})
class AxiosController extends VuexModule {
    requestInterceptor = 0
    responseInterceptor = 0

    /**
     * Checks if token needs to be refreshed
     * Always readd request interceptor if ejecting for a call
     * */
    @Mutation
    public setRequestInterceptor(): void {
        this.requestInterceptor = axios.interceptors.request.use(async (config) => {
            if (config.headers) {
                if (AzureIdentificationModule.expirationTime) {
                    const expDate = new Date(AzureIdentificationModule.expirationTime)
                    expDate.setUTCMinutes(expDate.getUTCMinutes() - 5)

                    if (expDate.getTime() <= Date.now()) {
                        await grabToken()
                        const result = dispatchEvent(new Event('RefreshTokenTimer'))
                    }
                }
                config.headers['Authorization'] = `Bearer ${AzureIdentificationModule.token ?? ''}`
            }
            return config
        }, (error) => {
            console.log('Issue with axios request')
            console.log(error)
            return Promise.reject(error)
        })
    }

    /**
     * Use is for basic response error handling
     * Can be ejected if specific handling required
     * */
    @Mutation
    public setResponseInterceptor(): void {
        // After ejecting it, it needs to be readded in order for other calls to use it
        this.responseInterceptor = axios.interceptors.response.use((response) => {
            return response
        }, (error) => {
            let errorMessage = ''
            if (error.response.status) {
                switch (`${error.response.status}`) {
                    case '404':
                        errorMessage = "Oops! Looks like there's nothing here!"
                        break;
                    case '403':
                        errorMessage = 'You do not have permission to access this resource.'
                        break;
                    case '401':
                        console.log(error)
                        errorMessage = 'You are unauthorized to access this resource.'
                        break;
                    default:
                        errorMessage = 'An unknown error has been encountered. Please try again later.'
                }
            } else {
                errorMessage = 'An unknown error has been encountered. Please try again later.'
            }
            notify(errorMessage, 'error', 5000)

            return Promise.reject(error)
        })
        //this.responseInterceptor = num
    }

    /**
     * Always readd request interceptor if ejecting for a call
     * */
    @Mutation
    public ejectRequestInterceptor(): void {
        axios.interceptors.response.eject(this.requestInterceptor)
        this.requestInterceptor = 0
    }

    /**
     * Please readd response interceptor if ejecting for a call
     * */
    @Mutation
    public ejectResponseInterceptor(): void {
        axios.interceptors.response.eject(this.responseInterceptor)
        this.responseInterceptor = 0
    }
}

export const AxiosModule = getModule(AxiosController)
