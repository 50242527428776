import {
    VuexModule, Module, Mutation, getModule
} from 'vuex-module-decorators'
import store from '@/store'
export interface UserAccountInfo {
    id: string | null
    email: string | null
    portal: number | null
    permissionStatus: number | null
    inAddressBook: boolean | null
    type: string | null
    fullName: string | null
    first: string | null
    last: string | null
    preferredName: string | null
    jobTitle: string | null
    company: string | null
    phone: string | null
    phoneSecondary: string | null
    addressLine1: string | null
    addressLine2: string | null
    city: string | null
    state: string | null
    zip: string | null
    country: string | null
    contestParticipation: string | boolean | null
    isContestEligible: boolean | null
    accountRepresentative: string | null
    lastReviewed: string | null
}

export interface BranchLocation {
    name: string
    branchNumber: number
    location: UserAccountAddress
}

export interface UserAccountAddress {
    addressLine1: string | null
    addressLine2: string | null
    city: string | null
    state: string | null
    zip: string | null
    country: string | null
}

export interface Divisions {
    abbr: string,
    name: string
}

export interface Country {
    abbr: string,
    name: string,
    d: Divisions[]
}

@Module({
    dynamic: true, store, namespaced: true, name: 'userAccount'
})
class UserAccount extends VuexModule  {
    currentUser: UserAccountInfo = {
        id: null,
        email: null,
        portal: null,
        permissionStatus: null,
        inAddressBook: null,
        type: null,
        fullName: null,
        first: null,
        last: null,
        preferredName: null,
        jobTitle: null,
        company: null,
        phone: null,
        phoneSecondary: null,
        addressLine1: null,
        addressLine2: null,
        city: null,
        state: null,
        zip: null,
        country: null,
        contestParticipation: false,
        isContestEligible: null,
        accountRepresentative: null,
        lastReviewed: null,
    }

    countryList: Country[] = []

    @Mutation
    setUserInfo(user: UserAccountInfo): void {
        this.currentUser = user
    }

    @Mutation
    setCountries(list: Country[]): void {
        this.countryList = list
    }

    branchLocations: BranchLocation[] = [
        {
            name: 'Corporate - Coye',
            branchNumber: 60,
            location: {
                addressLine1: '5233 Coye Drive',
                addressLine2: '',
                city: 'Stevens Point',
                state: 'WI',
                zip: '54481',
                country: 'US'
            }
        },
        {
            name: 'Stevens Point, WI',
            branchNumber: 10,
            location: {
                addressLine1: '2700 Chamber Street',
                addressLine2: '',
                city: 'Stevens Point',
                state: 'WI',
                zip: '54481',
                country: 'US'
            }
        },
        {
            name: 'New London, WI',
            branchNumber: 20,
            location: {
                addressLine1: '325 East Beckert Road',
                addressLine2: '',
                city: 'New London',
                state: 'WI',
                zip: '54961',
                country: 'US'
            }
        },
        {
            name: 'Rome, GA',
            branchNumber: 30,
            location: {
                addressLine1: '30 Industrial Blvd SE',
                addressLine2: '',
                city: 'Rome',
                state: 'GA',
                zip: '30161',
                country: 'US'
            }
        },
        {
            name: 'Remote',
            branchNumber: 0,
            location: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: '',
                country: 'US'
            }
        }
    ]
}

export const UserAccountModule = getModule(UserAccount)