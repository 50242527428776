<template>
    <nav class="navbar navbar-dark navbar-expand-sm fixed-bottom bottom-0 justify-content-between row ski-footer allrights">
            <p>&copy; {{currentYear}} - Steel King Industries, Inc. All Rights Reserved.</p>
    </nav>
</template>
<script lang="ts">

    import { Options, Vue } from 'vue-class-component'

    @Options(
        {
            props: {
                AppName: String
            }
        }
    )
    export default class Footer extends Vue {
        AppName!: string;

        get ApplicationName(): string {
            return this.AppName
        }

        get currentYear(): number {
            return new Date().getFullYear();
        }
    }
</script>

<style scoped lang="scss">
    .allrights {
        text-align: left;
        padding-left: 4%;
        color: white;
        margin-top: 1em;
        background-color: black;
        font-size: 14px;
    }
</style>
