import {
    VuexModule, Module, Mutation, getModule, Action
} from 'vuex-module-decorators'
import store from '@/store'
import { Auth } from '@/services/MsalService';
import { auth } from '@/store/modules/identification/MsalAuth'
import { CurrentUser } from '@/model/currentuser/CurrentUser';
import axios from 'axios'
import { RouteLocationNormalizedLoaded } from 'vue-router';

export interface IAzureB2C {
    token: null | string
}

/* eslint-disable camelcase */
export interface TokenData {
    display_name: string
    authenticationSource: string
    oid: string // objectID
    email: string
    groups: string[]
    roles: string[]
    newUser: boolean | null
    scp: string // scope
    exp: number // expiration 
}
/* eslint-enable camelcase */

export async function grabToken(): Promise<string> {
    const token = await Auth.getToken()
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    AzureIdentificationModule.setToken(token)
    console.log(AzureIdentificationModule.expirationTime)
    if (AzureIdentificationModule.isInRole('Portal-Customer')) {
        await getAzureStorageData()
    }
    return token
}

export async function grabTokenOverride(): Promise<string> {
    const token = await Auth.getTokenForceRefresh()
    console.log('grabTokenOverride method')
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    AzureIdentificationModule.setToken(token)
    console.log(AzureIdentificationModule.expirationTime)
    if (AzureIdentificationModule.isInRole('Portal-Customer')) {
        await getAzureStorageData()
    }
    return token
}

export async function getAzureStorageData(): Promise<void> {
    try {
        axios.get('api/ResourceHandler/SAS').then(response => {
            const sasURI = response.data
            const urlParts = sasURI.split('?');
            //console.log(urlParts)
            AzureIdentificationModule.setResourcesToken(urlParts[1])
        }).catch(error => console.log(error))
    } catch (err) {
        //console.log(err);
        throw err;
    }
}

@Module({
    dynamic: true, store, namespaced: true, name: 'azureIdentification'
})
class AzureIdentification extends VuexModule implements IAzureB2C {
    private tokenHolder: string | null = null

    public InternalRoles: string[] = ['IT', 'SalesAdmin', 'InternalSales', 'OrderEntry']

    public errorCode: string | null = null

    public currentUser: CurrentUser | null = null

    public resourcesToken: string | null = null

    private get tokenData(): TokenData | null {
        if (this.tokenHolder) {
            const tokenData = JSON.parse(atob(this.tokenHolder.split('.')[1])) as TokenData
            return tokenData
        }
        return null
    }

    get authenticated(): boolean {
        return auth.account != null
    }

    get isLocalAccount(): boolean {
        if (this.tokenData) {
            return this.tokenData.authenticationSource == 'localAccountAuthentication'
        }
        return false
    }

    /**
     * SSO Only
     */
    get displayName(): string | null {
        if (this.tokenData) {
            return this.tokenData.display_name
        }
        return null
    }

    get hasTokenData(): boolean {
        return this.tokenData != null
    }

    get portalID(): string | null {
        if (this.tokenData) {
            return this.tokenData.oid
        }
        return null
    }

    get portalEmail(): string | null {
        if (this.tokenData) {
            return this.tokenData["email"]
        }
        return null
    }

    get isNewUser(): boolean {
        if (this.tokenData) {
            return this.tokenData.newUser ?? false
        }
        return false
    }

    get azureRoles(): string[] | null {
        if (this.tokenData) {
            return this.tokenData.groups
        }
        return null
    }

    /**
     * SSO Only
     */
    get ssoRoles(): string[] | null {
        if (this.tokenData) {
            return this.tokenData.roles
        }
        return null
    }

    /**
     * SSO Only
     */
    get isInASSORole(): boolean {
        if (this.allRoles) {
            return this.allRoles.some((role: string) => role.toLowerCase().indexOf('dp_') > -1)
        }
        return false
    }

    get allRoles(): string[] | null {
        if (this.azureRoles != undefined && this.ssoRoles != undefined) {
            return this.azureRoles.concat(this.ssoRoles)
        } else if (this.ssoRoles != undefined) {
            return this.ssoRoles
        } else if (this.azureRoles != undefined) {
            return this.azureRoles
        } else {
            return null
        }
    }

    get roles(): string[] | null {
        let allRoles: string[] = []
        if (this.allRoles) {
            allRoles = this.allRoles
        }
        if (this.currentUser) {
            allRoles = allRoles.concat(this.currentUser.roles)
            let customRole = ''
            if (this.currentUser.isInternalUser) {
                customRole = 'Internal'
            } else if (this.currentUser.isRsm) {
                customRole = 'RSM'
            } else {
                customRole = 'Customer'
            }
            allRoles = allRoles.concat([customRole])
        }
        allRoles = [...new Set([...allRoles])]
        return allRoles
    }

    get isInRole() {
        return (role: string | string[]) => {
            if (role && this.roles) {
                if (typeof role == 'string') {
                    return this.roles.some((identityRole: string) => role.toLowerCase() === identityRole.toLowerCase())
                } else {
                    return role.map((singleRole: string) => this.roles!.some((identityRole: string) => singleRole.toLowerCase() === identityRole.toLowerCase()))
                        .reduce((previousValue: boolean, currentValue: boolean) => previousValue || currentValue)
                }
            }
            return false
        }
    }

    get token(): string | null {
        return this.tokenHolder
    }

    get error(): string | null {
        return this.errorCode
    }

    get isTokenValid(): boolean {
        let isValid = false
        if (this.tokenData) {
            const seconds = this.tokenData.exp
            const expDate = new Date(0)
            expDate.setUTCSeconds(seconds)
            // set to 5 minutes before to start grabbing refresh token
            expDate.setUTCMinutes(expDate.getUTCMinutes() - 5) 
            const current = Date.now()
            //console.log(current)
            //console.log(expDate.getTime())
            isValid = current <= expDate.getTime()
        }
        return isValid
    }

    /**
     * Grabs the token's expiration datetime
     */
    get expirationTime(): Date | null {
        let time = null
        if (this.tokenData) {
            const seconds = this.tokenData.exp
            const expDate = new Date(0)
            expDate.setUTCSeconds(seconds)
            time = expDate
        }
        return time
    }

    @Mutation
    public setToken(token: string | null) {
        this.tokenHolder = token
    }

    @Mutation
    setError(code: string | null) {
        this.errorCode = code
    }

    @Mutation
    public setCurrentUser(user: CurrentUser) {
        this.currentUser = user
    }

    @Mutation
    public setResourcesToken(token: string): void {
        this.resourcesToken = token
    }

    get isInternal(): boolean {
        if (this.currentUser) {
            return this.currentUser.isInternalUser || this.currentUser.isRsm
        } else {
            return false
        }
    }
}

export const AzureIdentificationModule = getModule(AzureIdentification)
