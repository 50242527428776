import Vuex from "vuex";
import { IIdentificationState } from "@/store/modules/identification/identification";
import { IQuoteToolModule } from "./modules/QuoteToolModule";
import { IMarkupRoute } from "./modules/MarkupRouteModule";
import { IQSListing } from "./modules/qslisting";
import { IAnnouncementData } from "./modules/announcementdata";
import { IPhotoDownloadState } from "./modules/photodownload";



export interface IRootState {
    identification: IIdentificationState;
    quotetool: IQuoteToolModule;
    markupRoutes: IMarkupRoute;
    qslisting: IQSListing;
    announcementdata: IAnnouncementData;
    photodownloads: IPhotoDownloadState;
}

export default new Vuex.Store<IRootState>({})
