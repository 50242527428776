import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17947743"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "errorMessage"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.error)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, " An unknown error has been encountered. Please refresh the page. "))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]))
}