import {
    VuexModule, Module, Mutation, getModule
} from 'vuex-module-decorators'
import store from '@/store'

export interface IIdentificationState {
    token: null | string
}

/* eslint-disable camelcase */
export interface TokenData {
    email: string,
    role: string[],
    given_name: string
}
/* eslint-enable camelcase */

@Module({
    dynamic: true, store, namespaced: true, name: 'identification'
})
class Identification extends VuexModule implements IIdentificationState {
    public token: string | null = null

    @Mutation
    public setToken(newToken: string): void {
        this.token = newToken
    }

    get tokenData(): TokenData | null {
        if (this.token) {
            return JSON.parse(atob(this.token.split('.')[1]))
        }
        return null
    }

    get identified(): boolean | null {
        return this.tokenData !== null
    }

    get roles(): string[] | null {
        if (this.tokenData) {
            return this.tokenData.role
        }
        return null
    }

    get isInRole() {
        return (role: string) => {
            if (role && this.roles) {
                return this.roles.some((identityRole: string) => role.toLowerCase() === identityRole.toLowerCase())
            }
            return false
        }
    }
}

export const IdentificationModule = getModule(Identification)
