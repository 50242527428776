import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "router container-fluid scrollable-content" }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ErrorBoundary = _resolveComponent("ErrorBoundary")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isAuthenticated && !_ctx.isFullPage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Header),
          _createVNode(_component_ErrorBoundary, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_router_view)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_Footer, {
            AppName: "Dealer Portal",
            style: {"margin-top":"30px"}
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_router_view)
        ]))
  ]))
}