import type { Configuration } from '@azure/msal-browser'
import { LogLevel } from '@azure/msal-browser'

export const Scopes = ['https://steelkingapplications.onmicrosoft.com/access-api/access_as_user']

export const Config: Configuration = {
    // required
    auth: {
        // must match info in dashboard
        clientId: process.env.VUE_APP_MSAL_CLIENT_ID!, // This is the ONLY mandatory field; everything else is optional.
        authority: process.env.VUE_APP_MSAL_LOGIN_AUTHORITY!, // Choose sign-up/sign-in user-flow as your default.
        knownAuthorities: [
            process.env.VUE_APP_MSAL_KNOWN_AUTHORITY!, // You must identify your tenant's domain as a known authority.
            process.env.VUE_APP_MSAL_PASSWORD_CHANGE_AUTHORITY!,
            process.env.VUE_APP_MSAL_REDIRECT!,
            process.env.VUE_APP_MSAL_LOGIN_AUTHORITY!,
        ],
        // login redirect; must match path in dashboard
        redirectUri: window.location.origin, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
    // optional
    system: {
        allowRedirectInIframe: true,
        loggerOptions: {
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false,
            loggerCallback,
        }
    }
}

function loggerCallback(level: LogLevel, message: string, containsPii: boolean) {
    if (!containsPii) {
        const parts = message.split(' : ')
        const text = parts.pop()
        switch (level) {
            case LogLevel.Error:
                return console.error(text)

            case LogLevel.Warning:
                return console.warn(text)

            case LogLevel.Info:
                return console.info(text)

            case LogLevel.Verbose:
                return console.debug(text)

            default:
                return console.trace(text)
        }
    } else {
        console.log(message)
    }
}